<template>
  <section v-if="order && order.number" class="section">
    <div class="container">
      <div class="section__wrapper">
        <div class="order__info">
          <div class="order__text">
            <span class="order__type">№ заказа</span>
            <span class="order__result">{{ order.number }}</span>
          </div>
          <div class="order__text">
            <span class="order__type">Дата заказа</span>
            <span class="order__result">{{ order.date_create }}</span>
          </div>
          <div class="order__text">
            <span class="order__type">Наименований</span>
            <span class="order__result">{{ order.quantity }}</span>
          </div>
          <div class="order__text">
            <span class="order__type">Сумма заказа</span>
            <span class="order__result">{{ order.sum }} ₽</span>
          </div>
          <div class="order__text">
            <span class="order__type">Адрес</span>
            <span class="order__result">{{ shop.UF_NAME }}</span>
          </div>
          <div class="order__text">
            <span class="order__type">Время работы</span>
            <span class="order__result">11:00 — 23:00</span>
          </div>
          <div v-if="property.POINTS > 0" class="order__text">
            <span class="order__type">Начислено баллов</span>
            <span class="order__result">{{ property.POINTS }} Б</span>
          </div>
        </div>
        <ul v-if="basket" class="list-reset order__list">
          <template v-for="item in basket">
          <li class="order__item">
            <router-link :to="'/detail/'+item.product_id" href="#" class="order__link">
              <div class="order__img">
                <img v-if="item.picture" :src="item.picture" >
              </div>
              <div class="order__product">
                <h4 class="order__name">{{ item.name }}</h4>
                <div class="order__amount flex">
                  <div class="count">
                    <span class="volume">{{ item.quantity }} {{ item.measure }}</span>
                  </div>
                  <span class="border"></span>
                  <div class="summ">
                    <span class="currency">{{ item.final_price }} ₽</span>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          </template>
        </ul>
        <button v-if="false && order.pay" @click="goToPay" class="btn-reset personal__form--btn order__repeat">Оплатить</button>
<br><br>
        <button v-if="false" @click="repeat" class="btn-reset personal__form--btn order__repeat">Повторить заказ</button>
      </div>
    </div>
  </section>
</template>

<script>
import ProductItem from '../components/app/ProductItem'
import axios from "axios";

export default {
  data: () => ({
    property: false,
    basket: false,
    shop: false,
    error: false,
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    order() {
      if (this.$store.getters.orderDetail.property != undefined)
        this.property = this.$store.getters.orderDetail.property;
      if (this.$store.getters.orderDetail.basket != undefined)
        this.basket = this.$store.getters.orderDetail.basket;
      if (this.$store.getters.orderDetail.shop != undefined)
        this.shop = this.$store.getters.orderDetail.shop;
      //console.log(this.$store.getters.orderDetail.property.CONTACT_PERSON)
      return this.$store.getters.orderDetail;
    }
  },
  created() {
    this.$store.dispatch('clearOrderDetail');
    this.getDetail();
  },
  components: {ProductItem},
  methods: {
    goToPay() {
      window.location.href = this.order.pay;
    },
    async getDetail() {
      await this.$store.dispatch({type: 'fetchOrderDetail', id:this.$route.params.id})
    },
    repeat() {
      this.error = false;
      let _this = this;
      axios.post( '/local/api/order/repeat',
          {
            id: this.$route.params.id
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.$router.push('/basket');
        }
      })
          .catch(function(){

          });
    },
    pay() {
      this.error = false;
      let _this = this;
      axios.post( '/local/api/order/pay',
          {
            id: this.$route.params.id
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        /*if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.$router.push('/basket');
        }*/
      })
          .catch(function(){

          });
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
}
</script>

