<template>
  <section v-if="order" class="section">
    <div v-if="order.pay == null">
      <div class="container">
        <p class="forgot__password--descr">
          Если вы&nbsp;заказываете доставку, сделайте скриншот этого экрана и&nbsp;передайте курьеру
        </p>
        <h2 class="approve__title h2-title center-title">Ваш заказ на&nbsp;{{ order.sum }} ₽ оплачен</h2>
        <p class="forgot__password--descr">
          Пожалуйста, приготовьте паспорт перед получением заказа
        </p>
        <div class="approve__info">
          <div class="order__text">
            <span class="order__type">№ заказа</span>
            <span class="order__result">{{ order.id }}</span>
          </div>
          <div class="order__text">
            <span class="order__type">Адрес</span>
            <span class="order__result">{{ order.shop.UF_NAME }}</span>
          </div>
          <div class="order__text">
            <span class="order__type">Время работы</span>
            <span class="order__result" v-html="order.shop.UF_WORK"></span>
          </div>
        </div>
        <ul class="list-reset approve__links">
          <li v-if="!cancel && order.canceled != 'Y'" class="approve__item">
            <a @click="cancelOrder"  class="authorization-link approve__link">Отменить заказ</a>
          </li>
          <li v-if="cancel || order.canceled == 'Y'" class="approve__item">
            Заказ отменён
          </li>
          <li v-if="!cancel && order.canceled != 'Y'" class="approve__item">
            <a href="https://ya.ru" target="_blank" class="authorization-link approve__link delivery">Заказать доставку</a>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <button v-if="order.pay" @click="goToPay" class="btn-reset personal__form--btn order__repeat">Переадресация на оплату...</button>
    </div>
  </section>

</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";
import axios from "axios";

export default {
  data: () => ({
    cancel: false,
    error: false,
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    order() {
      return this.$store.getters.orderInfo(this.$route.params.id);
    }
  },
  created() {
    //if (this.$store.getters.orderInfo(this.$route.params.id) == undefined)
    this.getInfo();
  },
  components: {},
  methods: {
    async getInfo() {
      await this.$store.dispatch({type: 'fetchOrderInfo', id:this.$route.params.id})
      //var formPay = document.getElementById("submitBtn");
      if(this.order.pay) {
        //formPay.click();
          window.location.href = this.order.pay;
      }
    },
    goToPay() {
      window.location.href = this.order.pay;
    },
    cancelOrder() {
      this.error = false;
      let _this = this;
      axios.post( '/local/api/order/cancel',
          {
            id: this.$route.params.id
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          // _this.error = res.data.error;
          // $(window).scrollTop($(".info-block--alert").offset().top);
          alert(res.data.error)
        }
        if (res.data.status == 200) {
          //_this.$router.push('/basket');
          _this.cancel = true;
        }
      })
          .catch(function(){

          });
    }
  }
}
</script>
