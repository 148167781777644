<template>
  <section class="section">
    <div class="container">
      <div class="section__wrapper cart-wrapper">
        <div class="order__text">
          <span class="order__type">Адрес магазина</span>
          <span class="order__result">{{ user.CURRENT_SHOP.name }}</span>
        </div>
        <form action="https://jsonplaceholder.typicode.com/posts" method="POST"
              class="registration__form personal-form">
          <div v-show="error != false && error != []" class="info-block info-block--alert">
            <h3>Внимание</h3>
            <p v-for="(item, key) in error" :key="key">{{ item }}</p>
          </div>

          <label>
            <span class="personal__form--descr">Ваше имя <span>*</span></span>
            <input v-model="fio" required type="text" placeholder="Юрий" class="personal-input">
          </label>
          <label>
            <span class="personal__form--descr">Телефон <span>*</span></span>
            <InputMask v-model="phone" inputmode="tel" mask="+7 (999) 999-99-99" required class="personal-input input-tel" placeholder="+7 (___) ___-__-__"></InputMask>
          </label>
          <label>
            <span class="personal__form--descr">Комментарий</span>
            <input v-model="comment" type="text" class="personal-input" placeholder="Напишите, что нам нужно знать">
          </label>
        </form>
      </div>
    </div>
  </section>
  <section class="points__section">
    <div class="container">
      <h2 class="cart__subtitle h2-title points-title">Баллы</h2>
      <div class="points__wrapper">
        <div class="points__inner">
          <span class="points">Ваши баллы</span>
          <span class="points__amount">{{ user.UF_POINTS }}</span>
        </div>
        <form action="https://jsonplaceholder.typicode.com/posts" method="POST" class="personal-form points__form">
          <label>
            <span class="personal__form--descr">Оплатить баллами</span>
            <input v-model="points" type="number" pattern="[A-Za-z]{3}" placeholder="3" class="personal-input input-numbers">
          </label>
        </form>
      </div>
      <div class="points__info">
        <div class="points__summary flex">
          <span class="overall">Сумма заказа</span>
          <span class="overall__summ">{{ summary.price }} ₽</span>
        </div>
        <div class="points__summary flex">
          <span class="overall">Оплата баллами</span>
          <span class="overall__summ">{{ points }} ₽</span>
        </div>
      </div>
    </div>
  </section>
  <section class="product__section">
    <div class="container">
      <div class="product-wrapper">
        <div class="product-inner">
          <h2 class="cart__subtitle h2-title prod-title">К оплате</h2>
          <span class="cost product__summary">{{ summary.price - points }} ₽</span>
        </div>
        <a @click="sendOrder"  class="product__approve personal__form--btn">Оплатить онлайн</a>
        <p v-if="false">Текстовый блок о том, как осуществляется заказ</p>
      </div>
    </div>
  </section>
  <main v-if="false" class="main">
    <section class="section" v-if="props">
      <div class="site-container">
        <div class="order">
          <div class="order__total">
            <div class="order__total-sum-row">
              <span class="order__total-sum-heading">Сумма к оплате</span>
              <span class="order__total-sum-value">{{ props.sum }}&nbsp;₽</span>
            </div>

            <div class="order__total-counter-row">
                            <span class="order__total-counter">
                                <span class="order__total-counter-value">{{ props.quantity }}</span> {{ declOfNum( props.quantity, ['наивенование','наименования','наименований']) }}
                            </span>
              <span v-if="props.discount > 0" class="order__total-discount">Скидка {{ props.discount }}&nbsp;₽</span>
            </div>
          </div>

          <div v-show="error != false && error != []" class="info-block info-block--alert">
            <h3>Внимание</h3>
            <p v-for="(item, key) in error" :key="key">{{ item }}</p>
          </div>

          <div class="order__form-block">
            <form class="order__form-block form" action="#" method="post" @submit.prevent="sendOrder">
              <div class="form__input-block">
                <label class="form__input-label">
                  ФИО контактного лица<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <input v-model="fio" required class="form__input input" type="text" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Контактный телефон<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <InputMask inputmode="tel"  v-model="phone" mask="+7 (999) 999-99-99" required class="form__input input" type="text" placeholder="+7 (___) ___-__-__" />
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput3">
                  E-mail<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <input v-model="email" required class="form__input input" type="text" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Адрес доставки<span class="form__input-required"> *</span>
                </label>

                  <Select2 class="form__input-wrapper" v-model="priem" :options="priem_options"  @select="selectPriem($event)" placeholder="Выбрать" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />

              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Укажите адрес<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <input v-model="address" required class="form__input input" type="text" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Время приёма отгрузок<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <Select2 class="form__input--half" v-model="priem_from" :options="from_options"  @select="mySelectEvent($event)" placeholder="с --:--" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />

                  <Select2 class="form__input--half" v-model="priem_to" :options="to_options" placeholder="до --:--" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />

                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Дата доставки<span class="form__input-required"> *</span>
                </label>
                <Select2 class="form__input-wrapper" v-model="date" :options="props.dates" placeholder="Выбрать" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Комментарий
                </label>
                <div class="form__input-wrapper">
                  <textarea v-model="comment" class="form__input form__input--textarea textarea" placeholder="Напишите, что нам нужно знать"></textarea>
                </div>
              </div>

              <button class="form__submit-btn btn" type="submit">Подтвердить заказ</button>
            </form>
          </div>

          <div class="order__info">
            <div class="order__info-block info-block info-block--success info-block--centered">
              <h3>Помощь по любым вопросам</h3>
              <p>
                Обращайтесь к вашему менеджеру
              </p>
              <a href="tel:+79000000000">+7 (900) 000-00-00</a>
              <span>Алексей</span>
            </div>

            <div class="order__info-block info-block info-block--info">
              <h3>Информация о доставке</h3>
              <p>
                Задача организации, в особенности же убеждённость некоторых оппонентов в значительной степени обусловливает важность прогресса профессионального сообщества. Идейные соображения высшего порядка, а также глубокий уровень погружения однозначно определяет каждого участника как способного принимать собственные решения касаемо анализа существующих паттернов поведения.

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import Select2 from 'vue3-select2-component';
import InputMask from 'primevue/inputmask';

export default {
  data: () => ({
    error: false,
    fio: '',
    phone: '',
    comment: '',
    points: 0
   }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    summary() {
      return this.$store.getters.flyBasket;
    }
  },
  watch: {
    points(val) {
      if (val > this.user.UF_POINTS)
        this.points = this.user.UF_POINTS
    }
  },
  methods: {
    sendOrder() {
      this.error = [];

      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }
      let formData = new FormData();
      formData.append('phone', this.phone);
      formData.append('fio', this.fio);
      formData.append('points', this.points);
      formData.append('comment', this.comment);

      let _this = this;
      axios.post( '/local/api/order/add',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          let orderInfo = {
            id: res.data.id,
            points: _this.points,
            fio: _this.fio,
            phone: _this.phone,
            comment: _this.comment,
            canceled: 'N',
            shop: _this.user.CURRENT_SHOP
          }
          _this.$store.commit('orderInfo', orderInfo);
          _this.$store.commit('fly_basket', {item:{}, items:{}});
          _this.$router.push('/order-complete/'+res.data.id);
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.fio = this.user.NAME;
    this.phone = this.user.PERSONAL_PHONE;
    this.$store.dispatch('fetchFlyBasket');
  },
  components: {InputMask}
}
</script>

