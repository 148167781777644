<template>
  <section class="section">
    <div class="container">
      <div class="section__wrapper">
        <p class="forgot__password--descr">Введите код, который отправлен на вашу почту и укажите новый пароль</p>
        <div class="info-block info-block--alert" v-show="error.length>0">
          <h3>Внимание</h3>
          <p v-for="item in error">{{item}}</p>
        </div>
        <form @submit.prevent="changePassword" method="POST"
              class="registration__form personal-form forgot__password--form">
          <label>
            <span class="personal__form--descr">Код <span>*</span></span>
            <input v-model="code" required type="text" class="personal-input forgot__password--input">
          </label>
          <label>
            <span class="personal__form--descr">Новый пароль <span>*</span></span>
            <input v-model="password" required type="password" class="personal-input forgot__password--input">
          </label>
          <label>
            <span class="personal__form--descr">Повторите новый пароль <span>*</span></span>
            <input v-model="password_confirm" required type="password" class="personal-input forgot__password--input">
          </label>
          <button type="submit" class="btn-reset personal__form--btn">Сохранить</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: [
  ],
  data: () => ({
    error: [],
    code: '',
    password: '',
    password_confirm: '',
    email: ''
  }),
  created() {

  },
  mounted() {
    this.email = this.$route.query.email;
    this.$store.dispatch('Loader');
  },
  methods: {
    changePassword() {
      this.error = [];

      if (!this.code)
        this.error.push('Введите код');
      if (!this.password)
        this.error.push('Введите пароль');
      if (!this.password_confirm)
        this.error.push('Введите подтверждение пароля');
      if (this.password != this.password_confirm)
        this.error.push('Введённые пароли не совпадают');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }

      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('code', this.code);
      formData.append('password', this.password);
      formData.append('password_confirm', this.password_confirm);
      let _this = this;
      axios.post( '/local/api/password/new',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200)
          _this.$router.push('/login');
      })
          .catch(function(){
            console.log('FAILURE!!');
          });


    },
  },
  components: {

  }
}
</script>

