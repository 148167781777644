<template>
  <div v-if="basket.quantity > 0" class="cart__popup cart__popup--active">
    <router-link to="/basket"  class="cart__link">
      <div class="cart__container">
        <div class="cart__total--price">
                <span class="cart__total">
                  {{ basket.price }} ₽<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z" fill="black"></path>
                  </svg>
                </span>
        </div>
        <div class="cart__total--amount">
          <span class="cart__total--cart">Корзина</span>
          <span class="card__total--quantity">{{ basket.quantity }} {{ declOfNum(basket.quantity, ['товар','товара','товаров']) }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>

export default {
  props: {
    type: ''
  },
  data: () => ({

  }),
  computed: {
    basket() {
      return this.$store.getters.flyBasket;
    }
  },
  methods: {
    async showBasket() {
      await this.$store.dispatch('fetchFlyBasket')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.showBasket();
  },
  components: {

  }
}
</script>