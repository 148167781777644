<template>
  <section class="section">
    <div class="container">
      <div class="section__wrapper">
        <router-link to="/"  class="personal__logo">
          <img src="img/personal-logo.svg" alt="Логотип ХМЕЛЬ и ХОЛОДЪ">
        </router-link>
        <h3 class="personal__title">Авторизуйтесь, чтобы делать заказы</h3>
        <ul class="list-reset personal__links">
          <li class="personal__links--item">
            <router-link to="/login"  class="personal__link authorization">Авторизация</router-link>
          </li>
          <li class="personal__links--item">
            <router-link to="/registration"  class="personal__link registration">Регистрация</router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: [

  ],
  data: () => ({

  }),
  computed: {

  },
  created() {

  },
  mounted() {
    this.$store.dispatch('Loader');
  },
  methods: {

  },
  components: {

  }
}
</script>

