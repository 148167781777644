<template>
  <section class="section">
    <div class="container">
      <div v-if="step=='reg'" class="section__wrapper">
        <a href="index.html" class="personal__logo">
          <img src="img/personal-logo.svg" alt="Логотип ХМЕЛЬ и ХОЛОДЪ">
        </a>
        <div v-if="error != false && error != []" class="info-block info-block--alert">
          <h3>Внимание</h3>
          <p v-for="(item, key) in error" :key="key">{{ item }}</p>
        </div>
        <form @submit.prevent="registration('sms')" method="POST"
              class="registration__form personal-form">
          <label>
            <span class="personal__form--descr">Ваше имя</span>
            <input v-model="fio" required type="text" placeholder="Как вас зовут?" class="personal-input" @paste="removeNumbers($event)" @keypress="removeNumbers($event)">
          </label>
          <label>
            <span class="personal__form--descr">Дата рождения <span>*</span></span>
            <Datepicker v-model="birthday" :clearable="false" language="ru" :locale="ru" inputFormat="dd.MM.yyyy" :enableTimePicker="false" :startingViewDate="startDate" required class="personal-input input-date" placeholder="__.__.____">
            </Datepicker>
          </label>
          <label>
            <span class="personal__form--descr">Телефон <span>*</span></span>
            <InputMask type="tel" v-model="phone" inputmode="tel" mask="+7 (999) 999-99-99" required class="personal-input input-tel" placeholder="+7 (___) ___-__-__"></InputMask>
          </label>
          <label>
            <span class="personal__form--descr">E-mail <span>*</span></span>
            <input v-model="email" required type="email" placeholder="test@mail.ru" class="personal-input">
          </label>
          <label>
            <span class="personal__form--descr">Придумайте пароль <span>*</span></span>
            <input v-model="password" required type="password" placeholder="Не менее 6 символов" class="personal-input">
          </label>
          <label>
            <span class="personal__form--descr">Повторите пароль <span>*</span></span>
            <input v-model="password_confirm" required type="password" placeholder="Не менее 6 символов" class="personal-input">
          </label>
          <label class="label-checkbox">
            <input v-model="agree_politics" type="checkbox" class="personal-input personal-checkbox"><span></span> <span
              class="checkbox-link">Я согласен с <a target="_blank" href="https://docs.google.com/document/d/168-MYziPkKZiifSn_CDoORAtdjeFGE2NVpzb9LTGAp0/edit" class="personal__form--link">Правилами приложения и Политикой обработки данных</a></span>
          </label>
          <button type="submit" class="btn-reset personal__form--btn">Зарегистрироваться</button>
        </form>
        <router-link to="/login" href="authorization.html" class="authorization-link">Авторизация</router-link>
      </div>
      <div v-if="step=='success'" class="registration__popup registration__popup--active">
        <div class="location__notice">
          <button v-if="false" class="btn-reset location__cancel"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.71967 6.71967C7.01256 6.42678 7.48744 6.42678 7.78033 6.71967L10 8.93934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L11.0607 10L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L10 11.0607L7.78033 13.2803C7.48744 13.5732 7.01256 13.5732 6.71967 13.2803C6.42678 12.9874 6.42678 12.5126 6.71967 12.2197L8.93934 10L6.71967 7.78033C6.42678 7.48744 6.42678 7.01256 6.71967 6.71967Z"
                  fill="black" />
          </svg>
          </button>
          <h5 class="registration__popup--subtitle">Регистрация прошла успешно!</h5>
          <router-link to="/login"  class="registration__popup--link">Войти</router-link>
        </div>
      </div>
      <div v-if="step=='sms'" class="section__wrapper">
        <p class="forgot__password--descr">Введите код, который был отправлен<br>
          вам на мобильный телефон</p>
        <div v-show="error && error.length" class="info-block info-block--alert">
          <h3>Внимание</h3>
          <p v-for="item in error">{{item}}</p>
        </div>
        <form @submit.prevent="registration('sms')" action="https://jsonplaceholder.typicode.com/posts" method="POST"
              class="registration__form personal-form forgot__password--form">
          <label>
            <span class="personal__form--descr">Код <span>*</span></span>
            <InputMask mask="9999" v-model="code" required type="text" placeholder="_ _ _ _" class="personal-input input-code"></InputMask>
          </label>
          <button type="submit" class="btn-reset personal__form--btn">Отправить</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import InputMask from 'primevue/inputmask';
import Datepicker from 'vue3-datepicker'
import {ru} from 'date-fns/locale'
window['ru'] = ru;

export default {
  data: () => ({
    email: '',
    password: '',
    password_confirm: '',
    phone: '',
    fio: '',
    birthday: '',
    birthday2: '',
    comment: '',
    error: false,
    agree_politics: false,
    step: 'reg',
    code: '',
    startDate: new Date(1990, 1)
   }),
  computed: {
    ru() {
      return window['ru'];
    }
  },
  components: {InputMask, Datepicker},
  methods: {
    registration(type) {

      this.error = [];
      if (this.agree_politics == false)
        this.error.push('Необходимо согласиться с Политикой обработки данных');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }
      let formData = new FormData();
      debugger;
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('password_confirm', this.password_confirm);
      formData.append('phone', this.phone);
      formData.append('fio', this.fio);
      formData.append('birthday', this.birthday.getFullYear()+"-"+(this.birthday.getMonth()+1)+"-"+this.birthday.getDate());
      if (type == 'getcode')
        formData.append('getcode', 'Y');
      else
        formData.append('code', this.code);
      let _this = this;
      axios.post( '/local/api/registration',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          if (type == 'getcode')
            _this.step = 'sms';
          else
            _this.step = 'success';
         // _this.$router.push('/registration-complete');
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    },
    handleFileUploads(event){
      for( var i = 0; i < event.target.files.length; i++ ){
        if (typeof event.target.files[i] == 'object') {
          this.files.push(event.target.files[i]);
        }
      }
    },
    delFile(name){
      for( var i = 0; i < this.files.length; i++ ){
        if (typeof this.files[i] == 'object' &&  this.files[i].name == name) {
          this.files.splice(i, 1);
        }
      }
    },
    addPoint() {
      this.priem.push({
        id: 'n'+Date.now(),
        address: '',
        from: '',
        to: ''
      });
    },
    deletePoint(index) {
      debugger;
      this.priem.splice(index, 1);
      console.log(this.priem)
    },
    mySelectEvent(event) {
      let to_options = [];
      for (let item in this.to_options_orig) {
                if (this.to_options_orig[item] > event.text === true)
          to_options.push(this.to_options_orig[item])
      }
      this.to_options = to_options;
    },
    removeNumbers(e) {
      let containsNumber = false;
      if (e.type == 'keypress') {
        containsNumber = /^\d+$/.test(e.key);
      } else if (e.type == 'paste') {
        let clipboardData = e.clipboardData || window.clipboardData,
            pastedData = clipboardData.getData('Text');
        containsNumber = /[0-9]/g.test(pastedData);
      }
      if (containsNumber) e.preventDefault();
    }
  },
}
</script>

