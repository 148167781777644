<template>
  <div>
<template v-if="basket">
  <section class="section">
    <div class="container">
      <div class="section__wrapper cart-wrapper">
        <span class="orders__number">Оформляйте заказ с 11:00 до 22:30 и забирайте до 23:00.</span>
        <div class="order__text">
          <span class="order__type">Адрес магазина</span>
          <span class="order__result">{{ current_shop.name }}</span>
        </div>
      </div>
    </div>
  </section>
  <section v-if="basket && summary.pivo > 0" class="drinks__section">
    <div class="container">
      <h2 class="cart__subtitle h2-title">Напитки</h2>
      <ul class="list-reset order__list">
        <template v-for="item in basket">
          <li v-if="typeof item == 'object' && item.type=='pivo'" class="order__item">
            <BasketItem :item="item"></BasketItem>
          </li>
        </template>

      </ul>
    </div>
  </section>
  <section v-if="basket && summary.sneki > 0" class="snacks__section">
    <div class="container">
      <h2 class="cart__subtitle h2-title">Закуски</h2>
      <ul class="list-reset order__list">
        <template v-for="item in basket">
          <li v-if="typeof item == 'object' && item.type=='sneki'" class="order__item">
            <BasketItem :item="item"></BasketItem>
          </li>
        </template>
      </ul>
    </div>
  </section>
</template>
  <Forget></Forget>

  <section class="product__section" v-if="summary.price > 0">
    <div class="container">
      <div class="product-wrapper">
        <div class="product-inner">
          <h2 class="cart__subtitle h2-title prod-title">Товаров на</h2>
          <span class="cost product__summary">{{ summary.price }} ₽</span>
        </div>
        <router-link v-if="summary.price > 0" to="/order" class="product__approve personal__form--btn">Перейти к оформлению</router-link>
      </div>
    </div>
  </section>
  </div>
</template>

<script>
import BasketItem from '../components/app/BasketItem'
import Forget from "../components/app/Forget";
export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    basket() {
      return this.$store.getters.basket;
    },
    current_shop() {
      return this.$store.getters.CURRENT_SHOP;
    },
    summary() {
      return this.$store.getters.flyBasket;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async showBasket() {
      await this.$store.dispatch('fetchBasket')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.showBasket();
  },
  components: {Forget, BasketItem},
}
</script>

