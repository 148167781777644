<template>
  <main v-if="false" class="main">
    <Slider></Slider>

    <section class="section">
      <div class="site-container">
        <div class="block-links block-links--index">
          <router-link :to="banner_url" class="block-links__link"  :style="banner_image">
            <div class="block-links__link-text">
              <span class="block-links__link-heading">{{ banner_header }}</span>
              <span class="block-links__link-count">{{ banner_text }} {{ declOfNum(banner_text, ['товар','товара','товаров']) }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <New></New>

    <Sale></Sale>


    <section class="section index-company">
      <div class="site-container">
        <h2 v-if="about_header" class="section__heading index-company__heading">{{about_header}}</h2>

        <div v-if="about_text" class="index-company__text">
          <p>
            {{about_text}}
          </p>
        </div>

        <img v-if="about_image" class="index-company__img" :src="about_image" :alt="about_header">
      </div>
    </section>
  </main>

    <section class="hero">
      <div class="container" v-if="false">
        <ul class="list-reset hero__list--links">
          <li class="hero__list--item">
            <router-link to="/page/delivery"  class="hero__list--link">
              <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.8601 9.26465C14.4005 9.26465 13.213 10.4521 13.213 11.9117C13.213 13.3713 14.4005 14.5587 15.8601 14.5587C17.3199 14.5587 18.5071 13.3713 18.5071 11.9117C18.5071 10.4521 17.3197 9.26465 15.8601 9.26465ZM15.8601 13.2352C15.1302 13.2352 14.5366 12.6416 14.5366 11.9117C14.5366 11.1818 15.1302 10.5882 15.8601 10.5882C16.59 10.5882 17.1836 11.1818 17.1836 11.9117C17.1836 12.6416 16.59 13.2352 15.8601 13.2352Z"
                    fill="black" />
                <path
                    d="M6.81619 9.26465C5.35658 9.26465 4.16914 10.4521 4.16914 11.9117C4.16914 13.3713 5.35658 14.5587 6.81619 14.5587C8.2758 14.5587 9.46324 13.3713 9.46324 11.9117C9.46324 10.4521 8.2758 9.26465 6.81619 9.26465ZM6.81619 13.2352C6.08628 13.2352 5.49267 12.6416 5.49267 11.9117C5.49267 11.1818 6.08628 10.5882 6.81619 10.5882C7.54589 10.5882 8.13971 11.1818 8.13971 11.9117C8.13971 12.6416 7.5461 13.2352 6.81619 13.2352Z"
                    fill="black" />
                <path
                    d="M17.6428 1.68779C17.5303 1.46433 17.3016 1.32336 17.0514 1.32336H13.5661V2.64689H16.6433L18.4453 6.23097L19.6281 5.63625L17.6428 1.68779Z"
                    fill="black" />
                <path d="M13.875 11.2719H8.86765V12.5954H13.875V11.2719Z" fill="black" />
                <path
                    d="M4.83098 11.2719H2.53691C2.17138 11.2719 1.87517 11.5681 1.87517 11.9336C1.87517 12.2991 2.17142 12.5953 2.53691 12.5953H4.83102C5.19655 12.5953 5.49276 12.2991 5.49276 11.9336C5.49276 11.5681 5.19651 11.2719 4.83098 11.2719Z"
                    fill="black" />
                <path
                    d="M20.861 7.22641L19.5594 5.54995C19.4343 5.38847 19.2413 5.29405 19.0368 5.29405H14.228V0.661741C14.228 0.296212 13.9317 0 13.5663 0H2.53691C2.17138 0 1.87517 0.296253 1.87517 0.661741C1.87517 1.02723 2.17142 1.32348 2.53691 1.32348H12.9045V5.95579C12.9045 6.32132 13.2007 6.61753 13.5662 6.61753H18.7127L19.6765 7.85899V11.2719H17.8456C17.4801 11.2719 17.1839 11.5682 17.1839 11.9337C17.1839 12.2992 17.4801 12.5954 17.8456 12.5954H20.3382C20.7037 12.5954 21 12.2991 21 11.9337V7.6323C21 7.48538 20.951 7.34244 20.861 7.22641Z"
                    fill="black" />
                <path
                    d="M4.78667 7.91882H1.74255C1.37702 7.91882 1.08081 8.21508 1.08081 8.58056C1.08081 8.94609 1.37706 9.24231 1.74255 9.24231H4.78663C5.15216 9.24231 5.44837 8.94605 5.44837 8.58056C5.44841 8.21508 5.15216 7.91882 4.78667 7.91882Z"
                    fill="black" />
                <path
                    d="M6.30877 5.31592H0.661741C0.296254 5.31592 0 5.61217 0 5.9777C0 6.34323 0.296254 6.63944 0.661741 6.63944H6.30877C6.6743 6.63944 6.97051 6.34319 6.97051 5.9777C6.97051 5.61221 6.6743 5.31592 6.30877 5.31592Z"
                    fill="black" />
                <path
                    d="M7.38958 2.71313H1.74255C1.37702 2.71313 1.08081 3.00939 1.08081 3.37488C1.08081 3.7404 1.37706 4.03662 1.74255 4.03662H7.38958C7.75511 4.03662 8.05132 3.74036 8.05132 3.37488C8.05136 3.00939 7.75511 2.71313 7.38958 2.71313Z"
                    fill="black" />
              </svg>
              Доставка
            </router-link>
          </li>
          <li class="hero__list--item">
            <router-link to="/catalog"  class="hero__list--link">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_484_2794)">
                  <path
                      d="M12.8114 2.19495C10.6344 0.0113601 7.4928 -0.595271 4.61291 0.610846C1.7366 1.81553 -0.0307952 4.46499 -5.16612e-05 7.52315C-0.0114911 10.5735 1.76449 13.2079 4.63579 14.399C5.60815 14.8027 6.60982 14.9992 7.59361 14.9992C9.51901 14.9992 11.3765 14.2447 12.8114 12.8042C15.7292 9.87894 15.7299 5.11949 12.8114 2.19423V2.19495ZM13.4156 6.85507C12.9366 6.83792 12.2809 6.81434 12.2523 7.50029C12.2523 7.85469 12.5405 8.14336 12.8958 8.14336H13.6858C13.387 11.0715 11.0734 13.3822 8.14342 13.6795C8.14199 13.5966 8.14485 13.5044 8.14842 13.4087C8.16558 12.9307 8.18918 12.2754 7.50281 12.2469C7.14819 12.2469 6.85934 12.5355 6.85934 12.8899V13.6788C3.95371 13.3565 1.64151 11.045 1.32049 8.1405C1.40343 8.13907 1.49494 8.14264 1.59075 8.1455C2.06978 8.16265 2.7254 8.18623 2.754 7.50029C2.754 7.14588 2.46587 6.85721 2.11124 6.85721H1.32049C1.4642 5.44103 2.09623 4.1113 3.10505 3.10453C4.11315 2.09563 5.44513 1.46399 6.86291 1.32108C6.86434 1.40397 6.86148 1.49614 6.85791 1.59189C6.84075 2.0699 6.81716 2.72512 7.50352 2.7537C7.67512 2.7537 7.8367 2.68654 7.95824 2.56578C8.07979 2.44431 8.14699 2.28283 8.14699 2.11063V1.3218C11.0526 1.64405 13.3648 3.95553 13.6858 6.86007C13.6029 6.8615 13.5114 6.85864 13.4156 6.85507Z"
                      fill="black" />
                  <path
                      d="M7.48999 3.43886C7.14323 3.44601 6.86582 3.72253 6.85939 4.0705V7.2337L5.66039 8.43196C5.4123 8.68847 5.41873 9.09861 5.67469 9.34655C5.79337 9.46159 5.94995 9.52589 6.11511 9.52875H6.11654C6.28598 9.52875 6.45114 9.46016 6.57054 9.34012L7.95758 7.95465C8.07841 7.83319 8.1449 7.6717 8.14633 7.4995V4.06907C8.14275 3.89759 8.07269 3.73682 7.949 3.61821C7.82531 3.49888 7.66158 3.43529 7.48999 3.43886Z"
                      fill="black" />
                </g>
                <defs>
                  <clipPath id="clip0_484_2794">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Предзаказ
            </router-link>
          </li>
        </ul>
      </div>
      <Slider></Slider>
    </section>
    <Forget v-if="isAuth"></Forget>
    <section v-if="sections" class="catalog">
      <div class="container">
        <h2 class="h2-title catalog__title">
          Каталог
        </h2>
        <div class="catalog__wrapper">

          <router-link :to="sections.beer.id" href="../beer.html" class="catalog__link">
            <div class="catalog__card flex beer" :style="'background-image: url('+sections.beer.image+');'">
              <h3 class="catalog__subtitle h3-title">{{ sections.beer.section }}</h3>
              <span class="catalog__count">{{ sections.beer.available }} {{ declOfNum(sections.beer.available, ['товар','товара','товаров']) }}</span>
            </div>
          </router-link>

          <div class="catalog__inner flex">
            <router-link :to="sections.craft.id"  class="catalog__link craft-link">
              <div class="catalog__card flex craft" :style="'background-image: url('+sections.craft.image+');'">
                <h3 class="catalog__subtitle h3-title">{{ sections.craft.section }}</h3>
                <span class="catalog__count">{{ sections.craft.available }} {{ declOfNum(sections.craft.available, ['товар','товара','товаров']) }}</span>
              </div>
            </router-link>
            <div class="catalog__bar flex">
              <router-link :to="sections.lemonade.id"  class="catalog__link">
                <div class="catalog__card flex lemonade" :style="'background-image: url('+sections.lemonade.image+');'">
                  <h3 class="catalog__subtitle h3-title lemonade-title">{{ sections.lemonade.section }}</h3>
                  <span class="catalog__count lemonade-count">{{ sections.lemonade.available }} {{ declOfNum(sections.lemonade.available, ['товар','товара','товаров']) }}</span>
                </div>
              </router-link>
              <router-link :to="sections.sidr.id"  class="catalog__link">
                <div class="catalog__card flex cider" :style="'background-image: url('+sections.sidr.image+');'">
                  <h3 class="catalog__subtitle h3-title">{{ sections.sidr.section }}</h3>
                  <span class="catalog__count">{{ sections.sidr.available }} {{ declOfNum(sections.sidr.available, ['товар','товара','товаров']) }}</span>
                </div>
              </router-link>
            </div>
          </div>

          <router-link :to="sections.snacks.id" href="../fish.html" class="catalog__link">
            <div class="catalog__card flex snacks" :style="'background-image: url('+sections.snacks.image+');'">
              <h3 class="catalog__subtitle h3-title snacks-title">{{ sections.snacks.section }}</h3>
              <span class="catalog__count snacks-count">{{ sections.snacks.available }} {{ declOfNum(sections.snacks.available, ['товар','товара','товаров']) }}</span>
            </div>
          </router-link>

        </div>
      </div>
    </section>
    <New></New>
    <section class="about">
      <div class="container">
        <template v-if="false">
        <h2 class="about__title h2-title">{{ about_header }}</h2>
        <img v-if="about_image" class="about__img" :src="about_image">
        <p>{{
            about_text
          }}</p>
        </template>
        <ul class="list-reset about__list flex">
          <li class="about__item"><router-link to="/shop"  class="about__link--address">Смотреть список адресов</router-link></li>
          <li v-if="phone" class="about__item"><a :href="'tel:+'+phonenumber" class="about__link--phone">{{ phone }}</a></li>
          <li v-if="vk" class="about__item"><a :href="vk" target="_blank" class="about__link--vk">
            <svg width="20" height="21" viewBox="0 0 20 21"
                                                                           fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.0677 14.8892C18.319 13.5417 16.3902 11.9225 15.594 11.2179C15.3761 11.025 15.3527 10.6913 15.5511 10.4779C17.0707 8.84502 18.2861 6.96877 18.7044 5.83502C18.889 5.33419 18.5069 4.80502 17.9686 4.80502H16.4048C15.8894 4.80502 15.5878 4.99002 15.444 5.2846C14.1811 7.87252 13.0977 8.99252 12.3394 9.6471C11.9148 10.0138 11.2527 9.71002 11.2527 9.1521C11.2527 8.0771 11.2527 6.68752 11.2527 5.7021C11.2527 5.22419 10.8619 4.83752 10.3798 4.83752L7.52316 4.80502C7.16358 4.80502 6.95816 5.2121 7.174 5.49752L7.64566 6.1746C7.82316 6.40919 7.919 6.6946 7.919 6.98752L7.9165 10.0367C7.9165 10.5671 7.27358 10.8263 6.89191 10.4546C5.60066 9.1971 4.48025 6.62919 4.07691 5.40127C3.96025 5.04585 3.62733 4.80585 3.24983 4.80502L1.70941 4.80127C1.1315 4.80127 0.708998 5.34919 0.866081 5.90085C2.27358 10.8413 5.1615 15.5413 10.3082 16.0509C10.8152 16.1009 11.2527 15.6925 11.2527 15.1871V13.5846C11.2527 13.125 11.6132 12.73 12.0765 12.7184C12.0927 12.7179 12.109 12.7179 12.1252 12.7179C13.4865 12.7179 15.0111 14.6992 15.5444 15.6259C15.6977 15.8925 15.9844 16.055 16.2944 16.055H18.3665C18.9711 16.055 19.359 15.4138 19.0677 14.8892Z"
                fill="#FBCD0B" />
          </svg>
          </a></li>
        </ul>

      </div>
    </section>
</template>

<script>
import Slider from "../components/app/Slider"
import Forget from "../components/app/Forget"
import New from "../components/app/New"


export default {
  data: () => ({

  }),
  beforeRouteLeave(to, from) {
    if (to.name != 'login')
      this.$store.dispatch('unLoader');
  },
  created() {
    //if (this.$store.getters.homeAbout === false || this.$store.getters.homeAbout == undefined)
      this.$store.dispatch('fetchHomeAbout');
    this.showSections();
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth;
    },
    user() {
      return this.$store.getters.user;
    },
    about_text() {
      return this.$store.getters.about_text;
    },
    about_header() {
      return this.$store.getters.about_header;
    },
    about_image() {
      return this.$store.getters.about_image;
    },
    phone() {
      return this.$store.getters.phone;
    },
    phonenumber() {
      return this.$store.getters.phone.replace(/[^0-9]/g,'');;
    },
    vk() {
      return this.$store.getters.vk;
    },
    sections() {
      return this.$store.getters.sections;
    }
  },
  mounted() {
   this.$store.dispatch('Loader');
 },
  components: {Slider, Forget, New},
  methods: {
    async showSections() {
      await this.$store.dispatch('fetchSections')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
}
</script>

