<template>
  <section v-if="product" class="section">
    <div class="container">
      <article class="product__card beer">
        <div class="product__top">
          <ul class="list-reset product__card--list">
            <li v-if="product.new" class="product__card--item product__new">
              NEW
            </li>
            <li v-if="product.sale" class="product__card--item product__percent">
              %
            </li>
            <li class="product__card--item product__rating">
              {{ product.rate }} <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.22791 0.807568C4.33543 0.576049 4.66457 0.576049 4.77209 0.807569L5.70461 2.81554C5.74832 2.90967 5.83757 2.97452 5.9406 2.987L8.13846 3.25338C8.39187 3.28409 8.49358 3.59713 8.30662 3.77093L6.68509 5.2783C6.60908 5.34896 6.57498 5.45388 6.59494 5.55573L7.02078 7.72833C7.06988 7.97883 6.8036 8.1723 6.58053 8.04819L4.64585 6.97183C4.55516 6.92137 4.44484 6.92137 4.35415 6.97183L2.41947 8.04819C2.1964 8.1723 1.93012 7.97883 1.97922 7.72833L2.40506 5.55573C2.42502 5.45389 2.39092 5.34896 2.31491 5.2783L0.693378 3.77093C0.506415 3.59713 0.608126 3.28409 0.86154 3.25338L3.0594 2.987C3.16243 2.97452 3.25168 2.90967 3.29539 2.81554L4.22791 0.807568Z" fill="black"></path>
            </svg>
            </li>
          </ul>
          <div class="product__cancel flex">
            <a @click="prevPage"  class="product__close"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.71967 6.71967C7.01256 6.42678 7.48744 6.42678 7.78033 6.71967L10 8.93934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L11.0607 10L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L10 11.0607L7.78033 13.2803C7.48744 13.5732 7.01256 13.5732 6.71967 13.2803C6.42678 12.9874 6.42678 12.5126 6.71967 12.2197L8.93934 10L6.71967 7.78033C6.42678 7.48744 6.42678 7.01256 6.71967 6.71967Z" fill="white"></path>
            </svg>
            </a>
          </div>
          <div class="product__image percent new rating flex">
            <img :src="product.image" class="product__img">
          </div>
        </div>
        <div class="product__content flex">
          <h3 class="product__title">{{ product.name }}</h3>
          <div class="product__text flex">
            <p v-if="product.color || product.company" class="product__descr">{{ product.color }}<br>{{ product.company }}</p>
            <div v-if="product.og || product.volume" class="product__info">
              <div v-if="product.og" class="product__must">
                <span class="product__must--quantity">{{ product.og }}</span>
                <span class="product__must--parameter">OG</span>
              </div>
              <div v-if="product.volume" class="product__alch">
                <span class="product__must--quantity">{{ product.volume }}</span>
                <span class="product__must--parameter">VOL</span>
              </div>
            </div>
            <span class="product__volume">
                {{ product.ratio }} {{ product.unit }}
              </span>
            <button v-if="quantity == 0" @click="changeQuantity('plus')" class="btn-reset product__order">
              <span v-if="false" class="product__buy">Купить</span>
              <span class="product__price">{{ product.price }}</span>
            </button>
            <div v-if="quantity > 0" class="product__count--buttons flex product__count--buttons__active">
              <button @click="changeQuantity('minus')" class="btn-reset product__count--button__minus">
              </button>
              <div class="count">
                <input class="quantity" type="number" v-model="quantity" disabled>
                <span class="volume">{{ product.unit }}</span>
              </div>
              <span class="border"></span>
              <div class="summ">
                <span class="cost">{{ product.price / product.ratio * quantity }}</span>
                <span class="currency">₽</span>
              </div>
              <button @click="changeQuantity('plus')" class="btn-reset product__count--button__plus"></button>
            </div>
            <p>
              {{ product.detail }}
            </p>
            <div class="product__about">
              <div v-if="product.style" class="product__style">
                <span class="product__parameter">Стиль</span>
                <span class="product__label">{{ product.style }}</span>
              </div>
              <div v-if="product.company" class="product__style">
                <span class="product__parameter">Производитель</span>
                <span class="product__label">{{ product.company }}</span>
              </div>
              <div v-if="product.volume" class="product__style">
                <span class="product__parameter">Объем</span>
                <span class="product__label">{{ product.volume }}</span>
              </div>
              <div v-if="product.tara" class="product__style">
                <span class="product__parameter">Тара</span>
                <span class="product__label">{{ product.tara }}</span>
              </div>
            </div>

            <Review></Review>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import Review from '../components/app/Review';
export default {
  data: () => ({
    quantity: 0
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    product() {
      return this.$store.getters.product;
    },
    basket() {
      if (!this.product)
        return 0;
      let basket_quantity = this.$store.getters.inBasket(this.product.id);
      if (basket_quantity != this.quantity)
        this.quantity = basket_quantity;
      return basket_quantity;
    }
  },
  watch: {
    basket(val) {
      if (val != this.quantity)
        this.quantity = val;
    }
  },
  methods: {
    prevPage() {
        this.$router.go(-1)
    },
    async showProduct() {
      await this.$store.dispatch({type:'fetchProduct', id:this.$route.params.id})
    },
    favorite() {
      debugger;
      if (this.product.favorite) {
        this.$store.dispatch({type:'deleteFavorite', id: this.product.id})
      }
      else {
        this.$store.dispatch({type:'addFavorite', id: this.product.id})
      }
      this.product.favorite = !this.product.favorite;
    },
    changeQuantity(type) {
      return false;
      if (!this.$store.getters.isAuth) {
        this.$router.push("/login");
        return;
      }
      let act = '';
      let quantity = this.quantity;
      if (type == 'plus') {
        if (this.product.quantity <= this.basket)
          return;
        if (quantity == 0)
          act = 'add';
        else
          act = 'update';
        quantity += this.product.ratio;
      }
      else {
        if (quantity > this.product.ratio) {
          act = 'update';
        }
        else if (quantity == this.product.ratio) {
          act = 'delete';
        }
        if (act)
          quantity -= this.product.ratio;
      }
      if (act == 'add')
        this.$store.dispatch({type:'addBasket', id: this.product.id, quantity: quantity})
      else if (act == 'update')
        this.$store.dispatch({type:'updateBasket', id: this.product.id, quantity: quantity})
      else if (act == 'delete')
        this.$store.dispatch({type:'deleteBasket', id: this.product.id, quantity: quantity})
    },
  },
  created() {
    this.$store.dispatch('clearProduct');
    this.showProduct();
  },
  components: {
    Review
  }
}
</script>

