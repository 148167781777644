<template>
  <section class="section">
    <Shop></Shop>
    <section v-if="sections" class="catalog">
      <div class="container">
        <div class="catalog__wrapper">

          <router-link :to="sections.beer.id" href="../beer.html" class="catalog__link">
            <div class="catalog__card flex beer" :style="'background-image: url('+sections.beer.image+');'">
              <h3 class="catalog__subtitle h3-title">{{ sections.beer.section }}</h3>
              <span class="catalog__count">{{ sections.beer.available }} {{ declOfNum(sections.beer.available, ['товар','товара','товаров']) }}</span>
            </div>
          </router-link>

          <div class="catalog__inner flex">
            <router-link :to="sections.craft.id"  class="catalog__link craft-link">
              <div class="catalog__card flex craft" :style="'background-image: url('+sections.craft.image+');'">
                <h3 class="catalog__subtitle h3-title">{{ sections.craft.section }}</h3>
                <span class="catalog__count">{{ sections.craft.available }} {{ declOfNum(sections.craft.available, ['товар','товара','товаров']) }}</span>
              </div>
            </router-link>
            <div class="catalog__bar flex">
              <router-link :to="sections.lemonade.id"  class="catalog__link">
                <div class="catalog__card flex lemonade" :style="'background-image: url('+sections.lemonade.image+');'">
                  <h3 class="catalog__subtitle h3-title lemonade-title">{{ sections.lemonade.section }}</h3>
                  <span class="catalog__count lemonade-count">{{ sections.lemonade.available }} {{ declOfNum(sections.lemonade.available, ['товар','товара','товаров']) }}</span>
                </div>
              </router-link>
              <router-link :to="sections.sidr.id"  class="catalog__link">
                <div class="catalog__card flex cider" :style="'background-image: url('+sections.sidr.image+');'">
                  <h3 class="catalog__subtitle h3-title">{{ sections.sidr.section }}</h3>
                  <span class="catalog__count">{{ sections.sidr.available }} {{ declOfNum(sections.sidr.available, ['товар','товара','товаров']) }}</span>
                </div>
              </router-link>
            </div>
          </div>

          <router-link :to="sections.snacks.id" href="../fish.html" class="catalog__link">
            <div class="catalog__card flex snacks" :style="'background-image: url('+sections.snacks.image+');'">
              <h3 class="catalog__subtitle h3-title snacks-title">{{ sections.snacks.section }}</h3>
              <span class="catalog__count snacks-count">{{ sections.snacks.available }} {{ declOfNum(sections.snacks.available, ['товар','товара','товаров']) }}</span>
            </div>
          </router-link>

        </div>
      </div>
    </section>
  </section>
</template>

<script>

import {Pagination} from "swiper";
import Shop from "../components/app/Shop"

export default {
  beforeRouteLeave(to, from) {
    this.$store.dispatch('unLoader');
  },
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    sections() {
      return this.$store.getters.sections;
    }
  },
  methods: {
    async showSections() {
      await this.$store.dispatch('fetchSections')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.showSections();
  },
  components: {Shop}
}
</script>

